import React, { useState } from 'react';
import {
  Category,
  MutationAddItemArgs,
  // AddItemMutationVariables,
  // MutationAddItemArgs,
  StockUnit,
  useAddItemMutation,
  useFetchProfileQuery,
} from '../../generated';
import { AddItemElectronicComponentForm } from './form/AddItemElectronicComponentForm';
import { useNavigate, useParams } from 'react-router-dom';
import { AddItemConsumableForm } from './form/AddItemConsumableForm';
import { AddItemToolForm } from './form/AddItemToolForm';
import {
  FormClearButton,
  FormSubmitButton,
  TextFieldText,
} from '@ukkometsot/parts-inventory-storybook';
import { Box, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import * as changeCase from 'change-case-all';

export default function AddItemCategoryPage() {
  // const { data, error } = useFetchProfileQuery({ defaultOptions: {} });

  const navigate = useNavigate();

  const [addItemMutation, { data: addData, called, loading }] =
    useAddItemMutation();
  const { category } = useParams();

  const initialFormData: MutationAddItemArgs = {
    description: '',
    stock: 1,
    stockUnit: StockUnit.Quantity,
    details: {},
    location: '',
    category: Category.Tool,
  };
  const [form, setForm] = useState(initialFormData);

  const handleFormChange = (event: any) => {
    console.log(event.target.id, event.target.value);

    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });

    console.log(form);
  };

  let formObject = <div>Invalid Category</div>;

  let categoryEnum: Category;

  const sx = { pt: 1.5, width: '50ch' };

  if (category === 'consumable') {
    formObject = (
      <AddItemConsumableForm
        onChange={handleFormChange}
        form={form}
        sx={sx}
      ></AddItemConsumableForm>
    );
    categoryEnum = Category.Consumable;
  } else if (category === 'electronic-component') {
    formObject = (
      <AddItemElectronicComponentForm
        onChange={handleFormChange}
        form={form}
        sx={sx}
      ></AddItemElectronicComponentForm>
    );
    categoryEnum = Category.ElectronicComponent;
  } else if (category === 'tool') {
    formObject = (
      <AddItemToolForm
        onChange={handleFormChange}
        form={form}
        sx={sx}
      ></AddItemToolForm>
    );
    categoryEnum = Category.Tool;
  }

  const getProgress = () => {
    if (called && loading) {
      return <LinearProgress />;
    }
    return <></>;
  };

  const getFormButtons = () => {
    if (loading) {
      return <></>;
    }
    return (
      <>
        <FormSubmitButton
          sx={{ marginRight: 2 }}
          onClick={submitForm}
        ></FormSubmitButton>
        <FormClearButton onClick={clearForm}></FormClearButton>
      </>
    );
  };

  const createObject = (form: any) => {
    const payload = Object.assign({}, form) as any;
    payload.category = categoryEnum;
    payload.stock = parseFloat(payload.stock.toString()); // -- move to component
    delete payload.details;
    payload.details = {};
    Object.entries(payload).forEach(([key, value]) => {
      console.log('-->', { key, value });
      if (/^details_/.test(key)) {
        const camelCaseCategory = changeCase.camelCase(payload.category);
        if (!payload.details[camelCaseCategory]) {
          payload.details[camelCaseCategory] = {};
        }
        const [, d] = key.split('_');
        payload.details[camelCaseCategory][d] = value;
        delete payload[key];
      }
    });
    return payload;
  };

  const submitForm = async (event: any) => {
    const payload = createObject(form);
    console.log('submit form', payload);
    try {
      if (payload.description.length < 1) {
        throw new Error('Validation Error'); // TODO SERVERSIDE
      }
      const { data } = await addItemMutation({ variables: payload });
      navigate(`/items/${data?.addItem.id}`);
    } catch (error: any) {
      alert(error.message);
    }
    return;
  };

  const clearForm = (event: any) => {
    console.log('clear form');
    setForm(initialFormData);
    return;
  };

  return (
    <Grid container spacing={4} sx={{ padding: 2 }}>
      <Grid xs={8}>{formObject}</Grid>
      <Grid xs={8}>
        {getFormButtons()}
        {getProgress()}
      </Grid>
    </Grid>
  );
}
