import { Grid } from '@mui/material';
import {
  SelectEnum,
  TextFieldNumeric,
  TextFieldText,
} from '@ukkometsot/parts-inventory-storybook';
import { useState } from 'react';
import {
  Category,
  ElectronicComponentType,
  MountType,
  MutationAddItemArgs,
  StockUnit,
} from '../../../generated';
import { AddItemCommonPartialForm } from './AddItemCommonPartialForm';
import * as changeCase from 'change-case-all';

export const AddItemElectronicComponentForm = ({
  onChange,
  form,
  sx,
}: {
  onChange: (event?: any) => void;
  form: any;
  sx: any;
}) => {
  return (
    <>
      <Grid sx={sx}>
        <h1>{changeCase.capitalCase(Category.ElectronicComponent)}</h1>
      </Grid>
      <AddItemCommonPartialForm
        form={form}
        onChange={onChange}
        sx={sx}
      ></AddItemCommonPartialForm>
      <Grid sx={sx}>
        <TextFieldText
          sx={{ width: '100%' }}
          id="details_manufacturer"
          label="Manufacturer"
          value={form.details_manufacturer}
          onChange={onChange}
        ></TextFieldText>
      </Grid>
      <Grid sx={sx}>
        <TextFieldText
          sx={{ width: '100%' }}
          id="details_manufacturerId"
          label="Manufacturer ID"
          value={form.details_manufacturerId}
          onChange={onChange}
        ></TextFieldText>
      </Grid>
      <Grid sx={sx}>
        <TextFieldText
          sx={{ width: '100%' }}
          id="details_case"
          label="Case"
          value={form.details_case}
          onChange={onChange}
        ></TextFieldText>
      </Grid>
      <Grid sx={sx}>
        <SelectEnum
          sx={{ width: '100%' }}
          id="details_componentType"
          label="Component Type"
          value={form.details_componentType}
          onChange={onChange}
          options={[
            ElectronicComponentType.None,
            ElectronicComponentType.Unknown,
            ElectronicComponentType.Ic,
            ElectronicComponentType.Capacitor,
            ElectronicComponentType.Resistor,
          ]}
        ></SelectEnum>
      </Grid>
      <Grid sx={sx}>
        <SelectEnum
          sx={{ width: '100%' }}
          id="details_mountType"
          label="Mount Type"
          value={form.details_mountType}
          onChange={onChange}
          options={[MountType.None, MountType.Smd, MountType.Tht]}
        ></SelectEnum>
      </Grid>
    </>
  );
};

// type: ElectronicComponentType
// mountType: MountType
// case: String
// manufacturerId: String
// manufacturer: String
