import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import {
  ConsumableButton,
  ElectronicComponentButton,
  NewItemIconButton,
  ToolButton,
} from '@ukkometsot/parts-inventory-storybook';

export default function AddItemPopOver() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <NewItemIconButton
        aria-describedby={id}
        onClick={handleClick}
      ></NewItemIconButton>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        New Item
      </Button> */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ButtonGroup size="small" aria-label="Small button group">
          <Link
            to="/add/tool"
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            <ToolButton label="Add Tool" sx={{ margin: 0.5 }}></ToolButton>
          </Link>
          <Link
            to="/add/electronic-component"
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            <ElectronicComponentButton
              label="Add Electronic Component"
              sx={{ margin: 0.5 }}
            ></ElectronicComponentButton>
          </Link>
          <Link
            to="/add/consumable"
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            <ConsumableButton
              label="Add Consumable"
              sx={{ margin: 0.5 }}
            ></ConsumableButton>
          </Link>
        </ButtonGroup>
      </Popover>
    </div>
  );
}
