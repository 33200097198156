import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthorizedApolloProvider = ({ children }: any) => {
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    // uri: 'http://127.0.0.1:1337/graphql',
    uri: 'https://inventory.laardee.com/graphql',
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token = localStorage.getItem('token');
    if (!token) {
      token = await getAccessTokenSilently({ cacheMode: 'on' });
      localStorage.setItem('token', token);
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
