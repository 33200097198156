import React from 'react';
import { useFetchProfileQuery } from '../generated';
import { Link } from 'react-router-dom';
import {
  ConsumableButton,
  ElectronicComponentButton,
  ToolButton,
} from '@ukkometsot/parts-inventory-storybook';
import { Grid } from '@mui/material';

export default function HomePage() {
  const { data, error } = useFetchProfileQuery({ defaultOptions: {} });
  console.log(data, error);
  return (
    <Grid container>
      <Grid xs={6} spacing={1} padding={2}>
        <Link to="/add/tool">
          <ToolButton label="Add Tool" sx={{ margin: 0.5 }}></ToolButton>
        </Link>
        <Link to="/add/electronic-component">
          <ElectronicComponentButton
            label="Add Electronic Component"
            sx={{ margin: 0.5 }}
          ></ElectronicComponentButton>
        </Link>
        <Link to="/add/consumable">
          <ConsumableButton
            label="Add Consumable"
            sx={{ margin: 0.5 }}
          ></ConsumableButton>
        </Link>
      </Grid>
    </Grid>
  );
}
