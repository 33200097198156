import {
  ItemDetailsCard,
  ItemsTable,
  SignInButton,
  SignOutButton,
  NewItemIconButton,
} from '@ukkometsot/parts-inventory-storybook';
import React from 'react';
import {
  StockUnit,
  useAddItemMutation,
  useGetStockAllQuery,
  useGetStockByIdQuery,
} from '../../generated';
import { Link, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import { IconButton, Tooltip } from '@mui/material';

export default function ItemsPage() {
  const { idSlug } = useParams();

  console.log({ idSlug });

  const { data, error, refetch } = useGetStockAllQuery();

  const { data: itemData, error: itemError } = useGetStockByIdQuery({
    variables: { id: idSlug as string },
  });

  const cleanedData =
    data?.stock.map((part) => {
      const d = Object.assign({}, part) as any;
      delete d?.__typename;
      d[' '] = (
        <Link to={`/items/${d.id}`}>
          <Tooltip title="Edit">
            <IconButton>
              <ChevronRightOutlinedIcon>
              </ChevronRightOutlinedIcon>
            </IconButton>
          </Tooltip>
        </Link>
      );
      return d;
    }) || [];

  console.log(cleanedData.length);

  if (cleanedData.length === 0) {
    return <></>;
  }

  return (
    <Grid container>
      <Grid xs={6} spacing={1} padding={2}>
        <ItemsTable content={cleanedData}></ItemsTable>
      </Grid>
      <Grid xs={4} spacing={1} padding={2}>
        <ItemDetailsCard item={itemData?.stock?.[0] ?? {}} />
      </Grid>
    </Grid>
  );
}
