import { Grid } from '@mui/material';
import {
  SelectEnum,
  TextFieldNumeric,
  TextFieldText,
} from '@ukkometsot/parts-inventory-storybook';
import { StockUnit } from '../../../generated';

export const AddItemCommonPartialForm = ({
  onChange,
  form,
  sx,
}: {
  onChange: (event?: any) => void;
  form: any;
  sx: any;
}) => {
  return (
    <>
      <Grid sx={sx}>
        <TextFieldText
          sx={{ width: '100%' }}
          id="description"
          label="Description"
          value={form.description}
          onChange={onChange}
        ></TextFieldText>
      </Grid>
      <Grid sx={sx}>
        <TextFieldNumeric
          sx={{ width: '50%' }}
          id="stock"
          label="Stock"
          value={form.stock}
          onChange={onChange}
        ></TextFieldNumeric>
        <SelectEnum
          sx={{ width: '50%' }}
          id="stockUnit"
          label="Stock Unit"
          onChange={onChange}
          value={form.stockUnit}
          options={Object.values(StockUnit)}
        />
        {/* <TextFieldText
          sx={{ width: '50%' }}
          id="stock"
          label="Stock"
          value={form.stock}
          onChange={onChange}
        ></TextFieldText> */}
      </Grid>
    </>
  );
};
