import { Grid, Paper } from '@mui/material';
import {
  FormClearButton,
  FormSubmitButton,
  SelectEnum,
  TextFieldNumeric,
  TextFieldText,
} from '@ukkometsot/parts-inventory-storybook';
import { useState } from 'react';
import {
  Category,
  MutationAddItemArgs,
  PowerSource,
  StockUnit,
} from '../../../generated';
import { AddItemCommonPartialForm } from './AddItemCommonPartialForm';
import * as changeCase from 'change-case-all';

export const AddItemToolForm = ({
  onChange,
  form,
  sx,
}: {
  onChange: (event?: any) => void;
  form: any;
  sx: any;
}) => {
  return (
    <>
      <Grid sx={sx}>
        <h1>{changeCase.capitalCase(Category.Tool)}</h1>
      </Grid>
      <AddItemCommonPartialForm
        form={form}
        onChange={onChange}
        sx={sx}
      ></AddItemCommonPartialForm>
      <Grid sx={sx}>
        <TextFieldText
          sx={{ width: '100%' }}
          id="details_manufacturer"
          label="Manufacturer"
          value={form.details_manufacturer}
          onChange={onChange}
        ></TextFieldText>
      </Grid>
      <Grid sx={sx}>
        <SelectEnum
          sx={{ width: '100%' }}
          id="details_powerSource"
          label="Power Source"
          value={form.details_powerSource}
          onChange={onChange}
          options={[
            PowerSource.Manual,
            PowerSource.Electric,
            PowerSource.BatteryPrimary,
            PowerSource.BatteryRechargeable,
          ]}
        ></SelectEnum>
      </Grid>
    </>
  );
};
