import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export enum Category {
  Consumable = 'CONSUMABLE',
  ElectronicComponent = 'ELECTRONIC_COMPONENT',
  Tool = 'TOOL',
}

export type Consumable = {
  __typename?: 'Consumable';
  case?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
};

export type ConsumableInput = {
  manufacturer?: InputMaybe<Scalars['String']['input']>;
};

export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ElectronicComponent = {
  __typename?: 'ElectronicComponent';
  case?: Maybe<Scalars['String']['output']>;
  componentType?: Maybe<ElectronicComponentType>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerId?: Maybe<Scalars['String']['output']>;
  mountType?: Maybe<MountType>;
};

export type ElectronicComponentInput = {
  case?: InputMaybe<Scalars['String']['input']>;
  componentType?: InputMaybe<ElectronicComponentType>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  manufacturerId?: InputMaybe<Scalars['String']['input']>;
  mountType?: InputMaybe<MountType>;
};

export enum ElectronicComponentType {
  Capacitor = 'CAPACITOR',
  Ic = 'IC',
  None = 'NONE',
  Resistor = 'RESISTOR',
  Unknown = 'UNKNOWN',
}

export type Item = {
  __typename?: 'Item';
  category?: Maybe<Category>;
  costs?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<ItemDetails>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  purchaseOrder?: Maybe<Scalars['String']['output']>;
  stock: Scalars['Float']['output'];
  stockUnit: StockUnit;
  unitValue?: Maybe<Scalars['Float']['output']>;
};

export type ItemDetails = Consumable | ElectronicComponent | Tool;

export type ItemDetailsInput = {
  consumable?: InputMaybe<ConsumableInput>;
  electronicComponent?: InputMaybe<ElectronicComponentInput>;
  tool?: InputMaybe<ToolInput>;
};

export enum MountType {
  None = 'NONE',
  Smd = 'SMD',
  Tht = 'THT',
}

export type Mutation = {
  __typename?: 'Mutation';
  addItem: Item;
  deleteItem: DeletionResponse;
};

export type MutationAddItemArgs = {
  category: Category;
  costs?: InputMaybe<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  details?: InputMaybe<ItemDetailsInput>;
  location?: InputMaybe<Scalars['String']['input']>;
  purchaseOrder?: InputMaybe<Scalars['String']['input']>;
  stock: Scalars['Float']['input'];
  stockUnit: StockUnit;
  unitValue?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationDeleteItemArgs = {
  id: Scalars['ID']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID']['output'];
};

export enum PowerSource {
  BatteryPrimary = 'BATTERY_PRIMARY',
  BatteryRechargeable = 'BATTERY_RECHARGEABLE',
  Electric = 'ELECTRIC',
  Manual = 'MANUAL',
}

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['ID']['output'];
  organizations: Array<Maybe<Organization>>;
};

export type Query = {
  __typename?: 'Query';
  profile: Profile;
  stock: Array<Maybe<Item>>;
};

export type QueryStockArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
};

export enum StockUnit {
  Kilogram = 'KILOGRAM',
  Litre = 'LITRE',
  Metre = 'METRE',
  Quantity = 'QUANTITY',
}

export type Tool = {
  __typename?: 'Tool';
  case?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  powerSource?: Maybe<PowerSource>;
};

export type ToolInput = {
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  powerSource?: InputMaybe<PowerSource>;
};

export type FetchProfileQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProfileQuery = {
  __typename?: 'Query';
  profile: {
    __typename?: 'Profile';
    id: string;
    organizations: Array<{ __typename?: 'Organization'; id: string } | null>;
  };
};

export type GetStockAllQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockAllQuery = {
  __typename?: 'Query';
  stock: Array<{
    __typename?: 'Item';
    id: string;
    description?: string | null;
    category?: Category | null;
    stock: number;
    stockUnit: StockUnit;
  } | null>;
};

export type GetStockByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetStockByIdQuery = {
  __typename?: 'Query';
  stock: Array<{
    __typename?: 'Item';
    id: string;
    description?: string | null;
    stock: number;
    stockUnit: StockUnit;
    category?: Category | null;
    location?: string | null;
  } | null>;
};

export type AddItemMutationVariables = Exact<{
  stock: Scalars['Float']['input'];
  stockUnit: StockUnit;
  description: Scalars['String']['input'];
  category: Category;
  details: ItemDetailsInput;
}>;

export type AddItemMutation = {
  __typename?: 'Mutation';
  addItem: { __typename?: 'Item'; id: string };
};

export type DeleteItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteItemMutation = {
  __typename?: 'Mutation';
  deleteItem: { __typename?: 'DeletionResponse'; success?: boolean | null };
};

export const FetchProfileDocument = gql`
  query FetchProfile {
    profile {
      id
      organizations {
        id
      }
    }
  }
`;

/**
 * __useFetchProfileQuery__
 *
 * To run a query within a React component, call `useFetchProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchProfileQuery, FetchProfileQueryVariables>(
    FetchProfileDocument,
    options
  );
}
export function useFetchProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchProfileQuery, FetchProfileQueryVariables>(
    FetchProfileDocument,
    options
  );
}
export function useFetchProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FetchProfileQuery, FetchProfileQueryVariables>(
    FetchProfileDocument,
    options
  );
}
export type FetchProfileQueryHookResult = ReturnType<
  typeof useFetchProfileQuery
>;
export type FetchProfileLazyQueryHookResult = ReturnType<
  typeof useFetchProfileLazyQuery
>;
export type FetchProfileSuspenseQueryHookResult = ReturnType<
  typeof useFetchProfileSuspenseQuery
>;
export type FetchProfileQueryResult = Apollo.QueryResult<
  FetchProfileQuery,
  FetchProfileQueryVariables
>;
export const GetStockAllDocument = gql`
  query GetStockAll {
    stock {
      id
      description
      category
      stock
      stockUnit
    }
  }
`;

/**
 * __useGetStockAllQuery__
 *
 * To run a query within a React component, call `useGetStockAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockAllQuery,
    GetStockAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockAllQuery, GetStockAllQueryVariables>(
    GetStockAllDocument,
    options
  );
}
export function useGetStockAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockAllQuery,
    GetStockAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockAllQuery, GetStockAllQueryVariables>(
    GetStockAllDocument,
    options
  );
}
export function useGetStockAllSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStockAllQuery,
    GetStockAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStockAllQuery, GetStockAllQueryVariables>(
    GetStockAllDocument,
    options
  );
}
export type GetStockAllQueryHookResult = ReturnType<typeof useGetStockAllQuery>;
export type GetStockAllLazyQueryHookResult = ReturnType<
  typeof useGetStockAllLazyQuery
>;
export type GetStockAllSuspenseQueryHookResult = ReturnType<
  typeof useGetStockAllSuspenseQuery
>;
export type GetStockAllQueryResult = Apollo.QueryResult<
  GetStockAllQuery,
  GetStockAllQueryVariables
>;
export const GetStockByIdDocument = gql`
  query GetStockById($id: ID!) {
    stock(id: $id) {
      id
      description
      stock
      stockUnit
      category
      location
    }
  }
`;

/**
 * __useGetStockByIdQuery__
 *
 * To run a query within a React component, call `useGetStockByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStockByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockByIdQuery,
    GetStockByIdQueryVariables
  > &
    (
      | { variables: GetStockByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockByIdQuery, GetStockByIdQueryVariables>(
    GetStockByIdDocument,
    options
  );
}
export function useGetStockByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockByIdQuery,
    GetStockByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockByIdQuery, GetStockByIdQueryVariables>(
    GetStockByIdDocument,
    options
  );
}
export function useGetStockByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStockByIdQuery,
    GetStockByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStockByIdQuery, GetStockByIdQueryVariables>(
    GetStockByIdDocument,
    options
  );
}
export type GetStockByIdQueryHookResult = ReturnType<
  typeof useGetStockByIdQuery
>;
export type GetStockByIdLazyQueryHookResult = ReturnType<
  typeof useGetStockByIdLazyQuery
>;
export type GetStockByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetStockByIdSuspenseQuery
>;
export type GetStockByIdQueryResult = Apollo.QueryResult<
  GetStockByIdQuery,
  GetStockByIdQueryVariables
>;
export const AddItemDocument = gql`
  mutation AddItem(
    $stock: Float!
    $stockUnit: StockUnit!
    $description: String!
    $category: Category!
    $details: ItemDetailsInput!
  ) {
    addItem(
      stock: $stock
      stockUnit: $stockUnit
      description: $description
      category: $category
      details: $details
    ) {
      id
    }
  }
`;
export type AddItemMutationFn = Apollo.MutationFunction<
  AddItemMutation,
  AddItemMutationVariables
>;

/**
 * __useAddItemMutation__
 *
 * To run a mutation, you first call `useAddItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemMutation, { data, loading, error }] = useAddItemMutation({
 *   variables: {
 *      stock: // value for 'stock'
 *      stockUnit: // value for 'stockUnit'
 *      description: // value for 'description'
 *      category: // value for 'category'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useAddItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddItemMutation,
    AddItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddItemMutation, AddItemMutationVariables>(
    AddItemDocument,
    options
  );
}
export type AddItemMutationHookResult = ReturnType<typeof useAddItemMutation>;
export type AddItemMutationResult = Apollo.MutationResult<AddItemMutation>;
export type AddItemMutationOptions = Apollo.BaseMutationOptions<
  AddItemMutation,
  AddItemMutationVariables
>;
export const DeleteItemDocument = gql`
  mutation DeleteItem($id: ID!) {
    deleteItem(id: $id) {
      success
    }
  }
`;
export type DeleteItemMutationFn = Apollo.MutationFunction<
  DeleteItemMutation,
  DeleteItemMutationVariables
>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteItemMutation,
    DeleteItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(
    DeleteItemDocument,
    options
  );
}
export type DeleteItemMutationHookResult = ReturnType<
  typeof useDeleteItemMutation
>;
export type DeleteItemMutationResult =
  Apollo.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteItemMutation,
  DeleteItemMutationVariables
>;
