import React from 'react';
import './App.css';
import { AuthenticationComponent } from '@ukkometsot/parts-inventory-storybook';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, Outlet, Route, Routes } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/HomePage';
import ItemsPage from './pages/ItemsPage/ItemsPage';
import NotAuthenticatedPage from './pages/NotAuthenticatedPage';
import AddItemPage from './pages/AddItemPage/AddItemPage';
import AddItemCategoryPage from './pages/AddItemPage/AddItemCategoryPage';
// import { useLocalStorage } from 'usehooks-ts';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddItemPopOver from './components/AddItemPopOver';

function returnPage(element: JSX.Element, isAuthenticated: boolean) {
  if (isAuthenticated) {
    return element;
  }
  return <NotAuthenticatedPage></NotAuthenticatedPage>;
}

function App() {
  const { isAuthenticated } = useAuth0();
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={returnPage(<HomePage />, isAuthenticated)} />
        <Route
          path="/items"
          element={returnPage(<ItemsPage />, isAuthenticated)}
        />
        <Route
          path="/items/:idSlug"
          element={returnPage(<ItemsPage />, isAuthenticated)}
        />
        <Route
          path="/add"
          element={returnPage(<AddItemPage />, isAuthenticated)}
        />
        <Route
          path="/add/:category"
          element={returnPage(<AddItemCategoryPage />, isAuthenticated)}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  return (
    <div>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/items">Items</Link>
          </li>
          <li>
            <AddItemPopOver></AddItemPopOver>
          </li>
          <li>
            <AuthenticationComponent
              authenticated={isAuthenticated}
              userName={user?.name}
              signInOnClick={() => loginWithRedirect()}
              signOutOnClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            ></AuthenticationComponent>
          </li>
        </ul>
      </nav>
      <hr />
      <Outlet />
    </div>
  );
}

export default App;
