import React, { useState } from 'react';
import {
  Category,
  MutationAddItemArgs,
  StockUnit,
  useFetchProfileQuery,
} from '../../generated';
import { AddItemElectronicComponentForm } from './form/AddItemElectronicComponentForm';
import {
  ConsumableButton,
  ElectronicComponentButton,
  SignInButton,
  ToolButton,
} from '@ukkometsot/parts-inventory-storybook';
import { Link } from 'react-router-dom';

export default function AddItemPage() {
  // const { data, error } = useFetchProfileQuery({ defaultOptions: {} });

  const initialFormData: MutationAddItemArgs = {
    category: Category.Tool,
    description: '',

    stock: 0,
    stockUnit: StockUnit.Kilogram,
  };
  const [form, setForm] = useState(initialFormData);

  // console.log(data, error);
  const handleFromChange = (event: any) => {
    console.log(event.target.id, event.target.value);
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });

    console.log({ form });
  };
  return (
    <div>
      <Link to="/add/tool">
        <ToolButton label="Add Tool"></ToolButton>
      </Link>
      <Link to="/add/electronic-component">
        <ElectronicComponentButton label="Add Electronic Component"></ElectronicComponentButton>
      </Link>
      <Link to="/add/consumable">
        <ConsumableButton label="Add Consumable"></ConsumableButton>
      </Link>
    </div>
  );
}
