import { Grid } from '@mui/material';
import {
  SelectEnum,
  TextFieldNumeric,
  TextFieldText,
} from '@ukkometsot/parts-inventory-storybook';
import { Category } from '../../../generated';
import { AddItemCommonPartialForm } from './AddItemCommonPartialForm';
import * as changeCase from 'change-case-all';

export const AddItemConsumableForm = ({
  onChange,
  form,
  sx,
}: {
  onChange: (event?: any) => void;
  form: any;
  sx: any;
}) => {
  return (
    <>
      <Grid sx={sx}>
        <h1>{changeCase.capitalCase(Category.Consumable)}</h1>
      </Grid>
      <AddItemCommonPartialForm
        form={form}
        onChange={onChange}
        sx={sx}
      ></AddItemCommonPartialForm>
            <Grid sx={sx}>
        <TextFieldText
          sx={{ width: '100%' }}
          id="details_manufacturer"
          label="Manufacturer"
          value={form.details_manufacturer}
          onChange={onChange}
        ></TextFieldText>
      </Grid>
    </>
  );
};
